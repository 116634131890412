.container {
    display: flex;
    width: 100%;
    height: 350px;
    align-items: center;
    justify-content: center;
    margin-bottom: 5rem;
    margin-top: 5rem;
    background: transparent !important;
    z-index: 1;
}

.innerContainer {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    color: #555555;
    align-items: center;

    .btn {
        background: #1F5BC3;
        color: #fff;
        border-radius: 8px;
        padding: 10px 20px;
        text-transform: capitalize;
        margin-top: 20px;
        font-size: 1.2rem;
        font-weight: bold;
    }
}

.primaryText {
    font-size: 2rem;
    color: #030B17;
    font-weight: bold;
    text-transform: capitalize;
    line-height: initial;
    position: relative;
    font-family: 'Nunito Bold', sans-serif;
    margin-bottom: 30px;
}

/*.primaryText::after{*/
/*    background-image: url('/images/header-underline.svg');*/
/*    background-repeat: no-repeat;*/
/*    height: 8px;*/
/*    position: absolute;*/
/*    width: 188px;*/
/*    content: '';*/
/*    bottom: -10px;*/
/*    left: 0px;*/
/*}*/

.contentText {
    font-size: 20px;
    line-height: 30px;
    width: 600px
}

.text {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}


.image {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
    justify-content: space-between;

    img {
        border-radius: 30px;
    }
}

/* tablets */
@media only screen and (max-width: 1260px) {
    .image img{
        width: 100%;
        height: auto;
    }
    .contentText{
        width:100%;
    }
    .primaryText{
        font-size: 2rem;
    }
    .innerContainer{
        justify-content: space-evenly;
    }
    .container{
        height: auto;
    }
    .text, .image{
        flex: initial;
    }
}

/* mobiles */
@media only screen and (max-width: 900px) {
    .image img{
        padding: 10px 0 10px 0;
        width: 100%;
        height: auto;
    }
    .contentText{
        width:100%;
        font-size: 1rem;
    }
    .primaryText{
        font-size: 2rem;
    }
    .innerContainer{
        justify-content: space-evenly;
        flex-direction: column-reverse !important;

        .btn {
            padding: 10px 15px;
            margin-top: 20px;
            font-size: 1rem;
        }
    }
    .container{
        height: auto;
    }
    .text, .image{
        flex: initial;
        max-width:100%;
    }

    .primaryText{
        font-size: 1.5rem;
        margin-bottom: 10px;
        margin-top: 20px;
    }
}
