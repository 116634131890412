
.midContentSection {
  background-color: #F5F9FF;
  position: relative;
  z-index: 1;
}

.midContentSection::before,
.midContentSection::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 213px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.midContentSection::before {
  top: -209px;
  background-image: image-set('/images/features/feature-top-line.png' 1x);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.midContentSection::after {
  bottom: -213px;
  background-image: image-set('/images/features/feature-bottom-line.png' 1x);
}


/* mobiles */
@media only screen and (max-width: 900px) {
  .midContentSection::before {
    top: -10px;
  }

  .midContentSection::after {
    bottom: -150px;
    background-image: image-set('/images/features/feature-bottom-line.png' 1x);
  }
}