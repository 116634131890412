
.contentContainer {
  display: flex;
  margin-bottom: 8rem;
  margin-top: 17rem;

  h3{
    font-family: Nunito Bold;
    font-size: 2rem;
    font-weight: bold;
    color: #030B17;
  }

  .contentCarousel{
    flex: 1;
    width:500px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-right: 70px;
  }

  .contentImage{
    flex: 1;
    margin-left: 70px;
  }
}

.cardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .cardHeaderInner {
    text-align: left;

    .name {
      font-size: 1.4rem;
      font-weight: bold;
      color: #030B17;

    }

    .position {
      font-size: 1.1rem;
      line-height: 2rem;
      color: #6a6666;
      margin-top: 0.7rem;
    }

    .star {
      color: #ffaf33;
    }

    .stars {
      margin-top: 0.7rem;
    }
  }
}


.review {
  font-size: 1.1rem;
  line-height: 2rem;
  color: #6a6666;
  margin: 2.5rem 0;
  overflow-y: auto;
  font-style: italic;
  text-align: left;
}

/* width */
.review::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.review::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.review::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.review::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.screenContainer {
  display: flex;
  flex-direction: row;
  padding-top: 60px;
}

.personContainer {
  //width: 500px;
  //position: relative;
  padding-right: 20px;
}

.personContainer a {
  text-decoration: underline;
  color: #0F3371;
}

.carousel {
  position: relative;

  :global .carousel-slider {
    display: flex;
    align-items: flex-end;

    button{
      position: absolute;
      left: 0px;
      z-index: 10;
    }

    button:last-child{
      position: absolute;
      left: 60px;
    }
  }
}

.rightBtn, .leftBtn {
  background: #5d9d6b;
  color: #fff;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 15px;

  svg{
    font-size: 16px;
  }

  &:hover {
    background: #359749;
    color: #fff;
  }
}

.testimonialsContainer {
  //width: 70%;
  //margin: 60px auto;
}

@media only screen and (max-width: 1450px) {
  .contentImage {
    img{
      width: 320px;
      height: 300px;
    }
  }
}


@media only screen and (max-width: 1030px) {
  .contentImage {
    display: none;
  }
}


@media only screen and (max-width: 900px) {
  .cardHeader img {
    display: none;
  }

  .container {
    margin-top: 10px;
  }

  .contentContainer{
    margin-top: 9rem;
  }

  .contentCarousel{
    width: 200px !important;
  }
}
